import './App.css';

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./components/ui/card"

import {Toaster} from "./components/ui/toaster"

import LogRocket from 'logrocket';


import AppManifest from './AppManifest';
import GithubApps from './AppsList';

import { KernelProvider, SignedIn, SignedOut, GithubLogin, UserSettings, useUser } from '@kernel/react';
import React from "react";
if (!process.env.REACT_APP_KERNEL_PUBLISHABLE_KEY) {
  throw new Error("Missing Kernel Publishable Key")
}
const kernelPubKey = process.env.REACT_APP_KERNEL_PUBLISHABLE_KEY;

if (process.env.REACT_APP_LOGROCKET_APPID) {
  LogRocket.init(process.env.REACT_APP_LOGROCKET_APPID);
}

function App() {
  return (
    <KernelProvider publishableKey={kernelPubKey} apiBaseUrl={process.env.REACT_APP_API_BASE_URL}>
      <Home></Home>
      <Toaster />
    </KernelProvider>
  );
}

function Home() {
  const user = useUser();

  return (
    <>
      <SignedIn>
        <Card className={"mx-20 my-10 bg-gray-50"}>
          <CardHeader>
            <CardTitle>
              Kernel _
              <span className={"float-right"}>
              <UserSettings />
          </span>
            </CardTitle>
              <CardDescription>Welcome back, {user && user.name}</CardDescription>
          </CardHeader>
          <CardContent>
              <div className="App">
                <AppManifest />
                <br />
                <br />
                <GithubApps />
              </div>
          </CardContent>
          <CardFooter>
            <p className={"text-xs"}>©️ 2023 Kernel Systems, Inc.</p>
          </CardFooter>
        </Card>
      </SignedIn>
      <SignedOut>
        <div className="flex items-center justify-center min-h-screen">
          <Card className={"bg-gray-50 text-center outline-none w-[350px] py-8 flex flex-col items-center justify-center"}>
            <CardHeader>
              <CardTitle>
                Kernel _
              </CardTitle>
              <CardDescription>
                <p className={"text-xs"}>early access</p>
              </CardDescription>
            </CardHeader>
            <CardContent>
              <GithubLogin />
            </CardContent>
            <CardFooter>
              <p className={"text-xs"}>©️ 2023 Kernel Systems, Inc.</p>
            </CardFooter>
          </Card>
        </div>
      </SignedOut>
    </>
  );
}

export default App;
