import React, { useState, useEffect, useRef } from "react";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "./components/ui/dialog"

import {Button} from "./components/ui/button"
import { Textarea } from "./components/ui/textarea"
import { Input } from "./components/ui/input"
import { Label } from "./components/ui/label"
import { Separator } from "./components/ui/separator"

import { useUser, OrgSelector } from '@kernel/react';
import { PenSquare, ChevronLeft } from 'lucide-react'


const AppManifestForm = () => {
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [description, setDescription] = useState("");
  const manifestRef = useRef();
  const [selectedOrg, setSelectedOrg] = useState(null);

  const user = useUser();

  const handleOrgSelection = (org) => {
      setSelectedOrg(org);
  };

  useEffect(() => {
    if (!url || !name || !description) return;

    const appManifest = {
      name,
      url,
      hook_attributes: {
        url: "https://api.kernel.dev/link/gh_events",
      },
      redirect_url: `${process.env.REACT_APP_API_BASE_URL}/application/github-redirect`,
      callback_urls: [
        `https://proxy.kernel.dev`
      ],
      setup_url: 'https://proxy.kernel.dev',
      description: description,
      public: true,
      default_permissions: {
        contents: "read",
        metadata: "read",
        pull_requests: "write",
        emails: "read",
        members: "read",
      },
      default_events: [
        "pull_request",
        "push",
      ],
      request_oauth_on_install: true,
      setup_on_update: true,
    };

    manifestRef.current.value = JSON.stringify(appManifest);
  }, [name, url, description]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };

  // const handleWebhookUrlChange = (event) => {
  //   setWebhookUrl(event.target.value);
  // };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem('kernelToken');

    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/application/spec`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-Kernel-Publishable-Key': process.env.REACT_APP_KERNEL_PUBLISHABLE_KEY,
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      // body: JSON.stringify({ name, url, description, webhook_url: webhookUrl }),
      body: JSON.stringify({ name, url, description }),
    });

    if (response.ok) {
      // If POST request to your backend is successful, submit the GitHub App Manifest form
      document.getElementById('githubAppManifestForm').submit();
    }
  }

  return (
    <>

    <Dialog className={"text-left"}>
      <DialogTrigger asChild>
        <Button><PenSquare className="mr-2 h-4 w-4" />New Application</Button>
      </DialogTrigger>
      <DialogContent className={"gap-0 p-0 outline-none"}>
        <DialogHeader className={"px-4 pb-4 pt-5"}>
          {selectedOrg ? (
            <>
           <DialogTitle>
             <Button onClick={() => setSelectedOrg("")} variant="outline" size="icon">
               <ChevronLeft className="h-4 w-4"/>
             </Button>
           </DialogTitle>
           <DialogDescription>Creating app in org {selectedOrg}. Upon submitting, you will temporarily be redirected to GitHub.</DialogDescription>
              <Separator />
           </>
          ) : (
            <>
            <DialogTitle>Create a new Application</DialogTitle>
            <DialogDescription>
              Ensure the Kernel app is installed in your GitHub organization (not personal account).
            </DialogDescription>
            </>
          )}
        </DialogHeader>
        {selectedOrg ? (
          <>
            <form onSubmit={handleSubmit} className={"p-4 pt-0"}>
              <Label htmlFor="app_name">
                Name
              </Label>
              <Input id="app_name" type="text" value={name} onChange={handleNameChange} required />
              <br />
              <Label htmlFor="base_url">
                App Base URL
              </Label>
              <Input id="base_url" type="url" value={url} onChange={handleUrlChange} required />
              <br />
              {/*<Label htmlFor="webhook_url">*/}
              {/*  Webhook URL*/}
              {/*</Label>*/}
              {/*<Input id="webhook_url" type="url" value={webhookUrl} onChange={handleWebhookUrlChange} required />*/}
              {/*<br />*/}
              <Label htmlFor="description">
                Description
              </Label>
              <Textarea id="description" value={description} onChange={handleDescriptionChange} required />
              <br />
              <DialogFooter>
                <Button type="submit">Submit</Button>
              </DialogFooter>
            </form>
            <form id="githubAppManifestForm" action={"https://github.com/organizations/" + selectedOrg + "/settings/apps/new?state=" + user.github_user_id} method="post">
              <input type="hidden" name="manifest" ref={manifestRef} readOnly />
            </form>
          </>
        ) : (
            <OrgSelector onOrgSelect={handleOrgSelection} selectedOrg={selectedOrg} />
        )}

      </DialogContent>
    </Dialog>
    </>
  );
};

export default AppManifestForm;
