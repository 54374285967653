import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Editor from "@monaco-editor/react";

import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./components/ui/table"

import { Button } from "./components/ui/button"
import { useToast } from "./components/ui/use-toast"
import { Input } from "./components/ui/input"
import { Card } from "./components/ui/card"
import {Check, ClipboardList} from "lucide-react"


import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "./components/ui/dialog"


const GithubApps = () => {
  const [apps, setApps] = useState([]);
  const [editedSchemas, setEditedSchemas] = useState({});
  const [editedWebhookURLs, setEditedWebhookURLs] = useState({});


  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { toast } = useToast()

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/application/github-apps/`;
    const token = localStorage.getItem('kernelToken');
    if (!token) return;  // Exit early if no token

    fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-Kernel-Publishable-Key': process.env.REACT_APP_KERNEL_PUBLISHABLE_KEY,
        'Content-Type': 'application/json'
      },
      credentials: 'include',
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      setApps(data);
      setLoading(false);
    })
    .catch(err => {
      setError(err.message);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, [localStorage.getItem('github_token')]); // Empty dependency array to fetch data only once when the component mounts

  const updateZodSchema = (uuid, zodSchema) => {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/application/github-apps/`;
    const token = localStorage.getItem('kernelToken');

    fetch(apiUrl, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-Kernel-Publishable-Key': process.env.REACT_APP_KERNEL_PUBLISHABLE_KEY,
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        uuid: uuid,
        zod_schema: zodSchema
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      toast({
        title: "Success!",
        description: "New deployment schema saved",
      })
    })
    .catch(err => {
      toast({
        title: "Error!",
        variant: "destructive",
        description: "Failure to update deployment schema",
      })
      console.error('Error updating zod schema:', err);
    });
  };

const updateWebhookURL = (uuid, webhookURL) => {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/application/github-apps/`;
    const token = localStorage.getItem('kernelToken');

    fetch(apiUrl, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-Kernel-Publishable-Key': process.env.REACT_APP_KERNEL_PUBLISHABLE_KEY,
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        uuid: uuid,
        webhook_url: webhookURL
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      toast({
        title: "Success!",
        description: "Webhook URL updated successfully",
      })
    })
    .catch(err => {
      toast({
        title: "Error!",
        variant: "destructive",
        description: "Failure to update Webhook URL",
      })
      console.error('Error updating webhook URL:', err);
    });
};

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (apps.length === 0) {
    return;
  }

  return (
    <Card>
      <Table>
        <TableCaption className={"font-bold text-xs"}>Have a question? Founder contact: deen@kernel.dev, (734)474-8538</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[100px]">Name</TableHead>
            <TableHead>Publishable key</TableHead>
            <TableHead></TableHead>
            <TableHead>Private key</TableHead>
            <TableHead></TableHead>
            <TableHead>Webhook URL</TableHead>
            <TableHead>Github URL</TableHead>
            <TableHead>Org</TableHead>
            <TableHead className="text-right">Form Schema</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
        {apps.map(app => (
          <TableRow>
            <TableCell className="font-medium">{app.name}</TableCell>
            <TableCell className={"flex align-middle items-center mt-2"}>
              <p className={"-mr-4"}>{app.publishable_key}</p>
            </TableCell>
            <TableCell>
              <CopyToClipboard text={app.publishable_key} onCopy={() => toast({ title: "Copied!", description: "Text has been copied to clipboard." })} ><span className={"flex items-center space-x-0"}><ClipboardList size={18} className={"lg:-ml-2"}></ClipboardList></span>
              </CopyToClipboard>
            </TableCell>
            <TableCell className={"blur-sm"}>{app.private_key}</TableCell>
            <TableCell>
              <CopyToClipboard text={app.private_key} onCopy={() => toast({ title: "Copied!", description: "Text has been copied to clipboard." })} ><span className={"flex items-center space-x-0"}><ClipboardList size={18} className={"lg:-ml-2"}></ClipboardList></span>
              </CopyToClipboard>
            </TableCell>
            <TableCell>
              <div className="grid grid-cols-8 gap-1">
                <Input
                  className={"col-span-7"}
                  type="text"
                  value={editedWebhookURLs[app.uuid] || app.webhook_url}
                  onChange={e => setEditedWebhookURLs(prev => ({ ...prev, [app.uuid]: e.target.value }))}
                />
                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => updateWebhookURL(app.uuid, editedWebhookURLs[app.uuid] || app.webhook_url)}
                >
                  <Check className="h-4 w-4" />
                </Button>
              </div>
            </TableCell>
            <TableCell>{app.html_url}</TableCell>
            <TableCell>{app.organization_name}</TableCell>
            <TableCell className="text-right">
              <Dialog>
                <DialogTrigger asChild><Button variant={"outline"}>Customize</Button></DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>ZOD form schema</DialogTitle>
                </DialogHeader>
                <DialogDescription>
                  <Editor
                    height="300px" // Adjust the height and width as needed
                    width="100%"
                    language="javascript" // You can adjust this depending on the code you're editing
                    value={editedSchemas[app.uuid] || app.zod_schema}
                    onChange={(value, e) => setEditedSchemas(prev => ({ ...prev, [app.uuid]: value }))}
                  />
                </DialogDescription>

                <DialogFooter>
                  <Button type="submit" onClick={() => updateZodSchema(app.uuid, editedSchemas[app.uuid] || app.zod_schema)}
                  >Save changes</Button>
                </DialogFooter>

              </DialogContent>
            </Dialog>
            </TableCell>
          </TableRow>
        ))}
        </TableBody>
      </Table>
    </Card>
  );
};

export default GithubApps;
